.dahi-highlight > pre {
	margin: 0 !important;
	border: 0 !important;
	padding-top: 40px !important;
}
.dahi-highlight {
	border-radius: inherit;
	overflow: auto;
}
.tabs-wrap {
	padding: 0 !important;
	border-right: 1px solid rgba(0, 0, 0, 0.3) !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
	overflow: hidden;
}
.tabs-component-tab:last-of-type {
	border-right: none;
}
.tabs-component-tab {
	display: inline-block;
	border-right: 1px solid rgba(0, 0, 0, 0.3);
	padding: 8px 12px;
	font-weight: 400;
	cursor: pointer;
	text-transform: capitalize;
}
.tabs-component-tab:hover {
	background: #f3f3f3;
}
.tabs-component-tab.is-active {
	font-weight: 700;
	color: #333;
	background: #fcfcfc;
}
