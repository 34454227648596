.footer-list .item,
.footer-list .item a,
.navbar .header.item,
.navbar .item {
	color: #f9fafb !important;
}
.map,
.map-card {
	position: absolute;
	right: 0;
}
body,
html {
	background-color: #141414;
}
#__next {
	min-height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: #f6f7f7;
}
.navbar .header.item {
	font-weight: 400 !important;
}
.root-container {
	flex: 1;
	display: flex !important;
	padding-left: 12px;
	padding-right: 12px;
}
.admin-cards-table,
.contact {
	display: flex;
}
.root-container.fluid {
	padding-left: 0;
	padding-right: 0;
}
.code-blok pre,
pre {
	overflow: auto;
}
._nav:hover,
.button-main:hover,
.item-name:hover {
	text-decoration: none;
}
.main-blue {
	color: #b7ecf3;
}
.main-pink {
	color: #f6bd87;
}
.button-main {
	color: #141414;
}
.button-footer {
	text-decoration-color: white;
	color: rgba(255, 255, 255, 0.5) !important;
}
.button-footer:active,
.button-footer:hover {
	color: #fff !important;
}
.contact {
	flex: 1 1 auto;
	-webkit-box-flex: 1;
	align-items: center;
	justify-content: center;
}
.contact-message {
	max-width: 640px;
	text-align: center;
}
.map {
	top: 0;
	left: 0;
	bottom: 0;
	padding: 41px 0 68px;
}
.map-card {
	bottom: 75px;
	left: 50%;
	transform: translate(-50%);
}
@media only screen and (max-width: 767px) {
	.row,
	.ui.container,
	.ui.items:not(.unstackable) > .item {
		margin: 0 !important;
	}
	.ui.items:not(.unstackable) > .item > .image,
	.ui.items:not(.unstackable) > .item > .image > img {
		max-height: 80px !important;
	}
}
.item-name {
	color: #000;
}
.api_item img {
	max-width: 80px;
}
#nprogress {
	pointer-events: none;
}
#nprogress .bar {
	background: #29d;
	position: fixed;
	z-index: 1031;
	top: 0;
	left: 0;
	width: 100%;
	height: 2px;
}
#nprogress .peg,
.nprogress-custom-parent #nprogress .bar,
.nprogress-custom-parent #nprogress .spinner {
	position: absolute;
}
#nprogress .peg {
	display: block;
	right: 0;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px #29d, 0 0 5px #29d;
	opacity: 1;
	-webkit-transform: rotate(3deg) translate(0, -4px);
	-ms-transform: rotate(3deg) translate(0, -4px);
	transform: rotate(3deg) translate(0, -4px);
}
#nprogress .spinner {
	display: block;
	position: fixed;
	z-index: 1031;
	top: 11px;
	right: 15px;
}
#nprogress .spinner-icon {
	width: 18px;
	height: 18px;
	box-sizing: border-box;
	border: 2px solid transparent;
	border-top-color: #29d;
	border-left-color: #29d;
	border-radius: 50%;
	-webkit-animation: 0.4s linear infinite nprogress-spinner;
	animation: 0.4s linear infinite nprogress-spinner;
}
.nprogress-custom-parent {
	overflow: hidden;
	position: relative;
}
@-webkit-keyframes nprogress-spinner {
	0% {
		-webkit-transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes nprogress-spinner {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
.code-blok {
	padding: 2rem 0 0 2rem;
}
.admin-cards-table > div {
	margin-right: 5px;
	padding: 6px 12px;
	border: 1px solid #ccc;
	border-radius: 3px;
}
.admin-cards-table span {
	font-weight: 700;
	text-transform: capitalize;
}
.yt-nav-tab div:first-child {
	flex-wrap: wrap;
}
.long_description_h1 {
	font-size: 25px !important;
}
.long_description_paragraf {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}
.ca-footer {
	background-color: #141414 !important;
	padding-bottom: 20px;
	width: 100%;
	color: #f9fafb;
}
.footer-list .item a:hover {
	color: #ffffff80 !important;
}

.suggest_list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	gap: 20px;
	margin-top: 20px;
}

.suggest_list > div {
	flex: 0 1 calc(33.333% - 20px);
	min-width: 250px;
	text-align: center;
	transition: all 0.3s ease;
	margin: 0 !important;
}

.suggest_list img {
	display: block;
	margin: 0 auto 10px;
}

.suggest_list .sl-title {
	margin: 10px 0;
	font-size: 1.1em;
	font-weight: bold;
}

.suggest_list .ui.button {
	margin-top: 10px;
}

@media (max-width: 768px) {
	.suggest_list > div {
		flex: 0 1 calc(50% - 20px);
	}
}

@media (max-width: 480px) {
	.suggest_list > div {
		flex: 0 1 100%;
		margin-left: 10px !important;
		margin-right: 10px !important;
	}
}
